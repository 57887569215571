.selectLanguage{
    position: absolute;
    bottom: 66px;
    background: #fff;
    z-index: 10;
    left: 0;
    width: 100%;
    max-height: 66vh;
    opacity: 0;
    visibility: hidden;
    &.visible{
        opacity: 1;
        visibility: visible;
    }
}
.translateBadge{
    // position: absolute;
    z-index: 10;
    // background: #5E50F9;
    color: #777;
    border-radius: 100%;
    // width: 25px;
    // height: 25px;
    font-size: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-weight: bold;

}
.container{
    border-top: 1px solid #f0eff5;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
}
.head{

}
.body{
    overflow: auto;
    height: 100%;
}
.bodyWrap{
    display: grid;
    // grid-template-rows: repeat(10, minmax(40px, max-content));
    grid-template-rows: repeat(12, minmax(40px,1fr));
    // column-gap: 0.5rem;
    grid-template-columns: repeat(3,minmax(200px,1fr));
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-flow: column;
    
}

.item{
    border-right: 1px solid #f0eff5;
    padding: 0 10px;

}

.itemBox{
    padding: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        background-color:#f5f7fb;
        border-radius: 3px;
    }
}

.active{
    margin-right: 3px;
    opacity:0 ;
&.show{
    opacity: 1;
}
}