// Panel New Style

// @import "https://fonts.googleapis.com/css?family=Public Sans:300,400,500,700&display=swap";
@font-face {
    font-family: "Public Sans";
    src: url("../fonts/PublicSans-VariableFont_wght.ttf");
}


/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 3px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 2px;
  }

/* Global Style */

body {
    font-family: "Public Sans", sans-serif, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol !important;
}

li {
    list-style-type: none;
}

.customModalContent {
    background-color: #fff;
}

.swalModalImage {
    width: 50%;
    border-radius: 8px;
    margin: auto;
}

.siteTempBtn {
    color: #fff;
    background-color: theme-color($primary);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    // padding: 11px;

    &:hover {
        background-color: #5246eb;
    }
}

.panelCustomSelectBox {
    font-size: 0.8125rem;
    >div {
        min-height: 47px;
    }
}

.customBox {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    box-shadow: 0 4px 18px rgba(47, 43, 61, .1), 0 0 transparent, 0 0 transparent;
    padding: 10px;
}

.customSiteTitle {
    margin-bottom: 15px;
    padding: 15px 10px;
    font-size: 14px;
    border-bottom:1px solid #f0eff5;
    i {
        margin-right: 5px;
    }
}

.customBoxText {
    // color: #343a40;
    text-decoration: none;
    line-height: 1.5;
    font-size: 15px;
    color: #505050;
}

.cardWrapper {
    padding: 10px;
}

#mainTableComponent {
    border: 0 solid #f7f7f7;

    tr {

        transition: all .4s ease;

        &:nth-child(2n) {
            background-color: #f5f6fa;
        }

        &:hover {
            background-color: #fafafa !important;
        }
    }


}

/* Global Style (END) */

/* Dashboard */

.statisticsCardIcon {
    width: 55px;
    height: 55px;
    display: grid;
    place-items: center;
    background-color: #ef476f;
    border-radius: 50%;
    margin-right: 10px;

    i {
        color: #fff;
    }
}

.statisticsCardTitle {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 7px;
}

.statisticsCardText {
    font-size: 14px;
    color: #505050;
}

.welcomeBackMainBox {
    height: 100%;
    background-color: #111824;
    color: #fff;
    font-size: 27px;
    line-height: 1.5;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &::before , &::after{
        content: '';
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top:-30px;
        left: -30px;
        opacity: 0.2;
    }

    &::after{
        top:unset;
        left: unset;
        bottom:-30px;
        right: -30px;   
    }
}

/* Dashboard (END) */

/* Sidebar */

.hoverSidebarMenuTitle {
    min-width: auto;
    position: fixed;
    left: 0;
    z-index: 14;
    opacity: 0;
    visibility: hidden;
    background-color: #111824;
    color: #fff;
    border-radius: 7px;
    transition: all .4s ease;
    padding: 15px 20px;
    font-size: 0.85rem;

    &.show {
        left: 75px;
        opacity: 1;
        visibility: visible;
    }
}

/* Sidebar (END) */

/* Chat Page */

.chatInfoMainBox {
    // height: 58vh;
    // height: calc(50vh - 82px);
    // overflow-y: hidden;
    transition: all .4s ease;
    margin-inline-end: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f0eff5;

    .messageCard{
        background-color: #f0eff5;
        color:#222;
        .messageCardName {
            color:#111;
        }
    }

    .chatInfoCard {
        // border-bottom: 1px solid #ccc;
        text-align: center;
        padding: 15px 10px;
        padding-bottom: 0;

        .chatInfoCardImage {
            width: 120px;
            height: 120px;
            object-fit: cover;
            border-radius: 50%;
        }

        .chatInfoCardName {
            color: #343a40;
            text-decoration: none;
            text-align: start;
            font-size: 14.5px;
            margin-top: 15px;
        }

        .chatInfoCardItem {
            width: 100%;
            // font-size: 13px;
            background-color: #e6ebf5;
            border-radius: 4px;
            box-shadow: none;
            padding: 10px 6px;
            margin-top: 7px;

            // > div:first-child{
            //     font-weight: bold;
            // }

            .chatInfoCardName {
                margin-top: 0;
            }
        }
    }

}

.chatInfoMainBoxFooter{
    border-top: 1px solid #f0eff5;
    padding: 10px;

    .textMessageInput {
        border-radius: 4px;
        border:1px solid #f0eff5;
    }

    .form-group {
        width: 100%;
        margin-bottom: 0 !important;

        .error-text {
            display: none;
        }
    }

    .chatInfoMainBoxFooterButton{
        height: 45px;
        background-color: theme-color(primary);
        color: #fff;
        display: grid;
        place-items: center;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
    }
}

.chatMainBox {
    // border: 1px solid #f5f7fb;
    // border-radius: 5px;
    height: calc(100vh - 70px);
    margin: -0.5rem;
}

.chatHeader{
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
}

.chatHeaderColumn{
    display: flex;
    align-items: center;
    gap: .75rem;
}

.chatSideBarMainBox {
    background-color: $white;
    border-right: 1px solid #f0eff5;
    overflow: hidden;
}
.chat-sidebar-item{
    // border: 1px solid #eee;
}

.chatSidebarMainBodyBox {
    height: calc(100% - 46px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.chatCard {
    border-radius: 3px;
    cursor: pointer;
    transition: all .4s ease;
    border: 1px solid;
    border-color: #f8f8f8;
    // background-color: #f8f8f8;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover,
    &.newMessage,
    &.selected {
        background-color: #e6ebf5;
    }


    .chatCardAvatar {

        display: flex;

        &>img {
            width: 42px;
            height: 42px;
            object-fit: cover;

            &:not(:first-child) {
                margin-left: -22px;
            }
        }

    }


    .chatCardDetail {
        width: calc(100% - 50px);
        flex: 1;
>div{
    // min-height:25px;
    position: relative;
}
        .chatCardLastMessage {
            font-size: 13px;
            width: 80%;
        }

        .chatCardName {
            font-size: 14px;
            text-transform: capitalize;
            margin-bottom: 3px;
            white-space: nowrap;
            max-width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;  
            margin-right: 10px;  
            span{
                    font-size: 13px;
                }
        }

        .chatCardLastTime {
            font-size: 12px;
            // padding: 10px;
        }

    }

    .chatCardUnReadCount {
        border-radius: 50%;
        color: #ef476f;
        background-color: rgba(239, 71, 111, .18);
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        position: absolute;
        right: 0;
        bottom: -7px;
    }
}


.showChatDetailsMainBox {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectOneChatMessageBox {
    text-align: center;

    &>i {
        width: 52px;
        border-radius: 50%;
        background-color: #fff;
        padding: 10px;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 25px;
    }
}

.singleChatWrapper{
    height: calc(100vh - 70px);
    overflow: hidden;
    width: 100%;
}

.singleChatMainBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all .4s ease;

    &.openSide {
        width: calc(100%-350px);
    }
}

.singleChatMainHeaderBox {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f0eff5;
    // padding: 20px;
    // max-height: 202px;
    // overflow: auto;
    .singleChatHeaderAvatar {
        align-self: start;
        font-size: 15px;
        &>img {
            height: inherit;
            width: 42px;
            height: 42px;
            border-radius: 3px;
            border: 1px solid #f0eff5;
            object-fit: cover;
        }
    }

    .singleChatHeaderBody{
        font-size: 0.87rem;
        text-transform: capitalize;
        span{
            font-size: 13px;
            text-transform: lowercase;
        }
    }

    .singleChatMainHeaderIcons {
        color: theme-color(primary);
        font-size: 20px;
    }
}
.singleChatNoteMainBody{
    height:100%; //calc(100% - 47px);
    background-color: #fff;
    flex-grow: 1;
    overflow: auto;
    position: relative;
    padding: 10px;
}
.singleChatMainBody {
    // height: 46vh;
    // height: calc(100vh - 338px);
    background-color: #fff;
    flex-grow: 1 !important;
    overflow: auto;
    position: relative;
    padding: 10px;
    height: 60%;
    display: flex;
    flex-direction: column-reverse;
    scroll-snap-align: end;
}

.chat-note-side{
    border-left: 1px solid #f0eff5;
    height: 50%;
}

.messageCardBox {
    margin-bottom: 10px;
    >img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-inline-end: 5px;
    }
}

.chat-image{
    img{
        height:250px;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.SCC-mainDate {
    width: 150px;
    border-radius: 50px;
    text-align: center;
    background-color: #f4f4f4;
    font-size: 13px;
    position: sticky;
    // bottom: 0;
    top: 0;
    padding: 10px;
    margin: 20px auto;
    z-index: 10;
}

.messageCard {
    // width: min-content;
    max-width: 60%;
    background-color: theme-color(teal);
    color: #fff;
    position: relative;
    border-radius: 8px 8px 8px 0;
    padding: 12px 20px;

    &.me {
        color: $sidebar-light-menu-hover-color;
        background-color: #f5f7fb;
        border-radius: 8px 8px 0 8px;
        margin-left: auto;

        .messageCardName {
            color: theme-color(indigo);
        }
    }

    .messageCardName {
        color: #f7f7ff;
        font-size: 0.76rem;
        text-transform: capitalize;
        margin-bottom: 5px;
        opacity: .5;
    }

    .messageCardFooter {
        text-align: end;
        margin-top: 3px;

        .messageCardFooterDate {
            font-size: 11px;
            opacity: .5;
        }
    }

}

.singleChatMainFooter {
    border-top: 1px solid #f0eff5;
    padding: 10px;
    position: relative;

    .textMessageInput {
        border-radius: 4px;
        border:1px solid #f0eff5;
    }

    .form-group {
        width: 100%;
        margin-bottom: 0 !important;

        .error-text {
            display: none;
        }
    }

    .singleChatMainFooterButton {
        width: 46px;
        height: 45px;
        background-color: theme-color(primary);
        color: #fff;
        display: grid;
        place-items: center;
        border-radius: 8px;
        cursor: pointer;
    }
}

.emojiChatFooterMainBox {
    z-index: 100;
    position: relative;


    .emojiChatBox {
        // display: none;
        position: absolute;
        bottom: 50px;
        transform: translateX(-80%);
        visibility: hidden;
        opacity: 0;
        transition: all .4s ease;
    }

    .emojiChatFooterIcon {
        font-size: 30px;
        cursor: pointer;
    }

    &:hover {
        .emojiChatBox {

            visibility: visible;
            opacity: 1;
        }
    }
}

.chatSearchInput {
    border-radius: 4px;
    border:1px solid #f0eff5;
}

/* Chat Page (END) */

/* Gallery */

.userGalleryCard {
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    position: relative;
    z-index: 2;
    padding-top: 30px;
    padding-bottom: 30px;

    &::before {
        content: "";
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        position: absolute;
        z-index: -1;
        top: 15px;
        left: 15px;
        border: 1px dashed #505050;
    }

    .userGalleryCardImage {

        >i {
            font-size: 100px;
            color: #505050;
        }
    }

    .userGalleryCardTitle {
        font-size: 22px;
    }

    .userGalleryCardText {
        font-size: 18px;
    }
}

.gallerySingleCard {
    height: 100%;
    min-height: 190px;
    position: relative;
    background-color: #f5f6fa;
    border-radius: 8px;
    transition: all .4s ease;
    overflow: hidden;
    padding: 10px;

    &.deleted {
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
    }


    .gallerySingleCardDeletedBadge {
        width: 120px;
        height: 70px;
        background-color: #ff0844;
        display: flex;
        align-items: end;
        justify-content: center;
        position: absolute;
        top: -20px;
        left: -50px;
        z-index: 9;
        transform: rotate(-45deg);

        >img {
            width: 60px;
        }
    }

    .gallerySingleCardImage {
        height: 100%;
        max-height: 220px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;


        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .4s ease;
        }

        .gallerySingleCardImageHover {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .4);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: end;
            padding-bottom: 10px;
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease;
        }

        &:hover {
            .gallerySingleCardImageHover {
                opacity: 1;
                visibility: visible;
            }

            >img {
                transform: scale(1.1);
            }
        }
    }

    .gallerySingleCardName {
        font-size: 14px;
        text-align: start;
    }

    .gallerySingleCardUploadFile {
        height: 100%;
        user-select: none;
        color: #222;
        border: 1px dashed #222;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        >i {
            font-size: 30px;
        }

        .gallerySingleCardUploadFileHeader {
            font-size: 16px;
            margin-top: 5px;
        }
    }
}

.avatar-name-holder{
    height: 42px;
    width: 42px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5E50F9;
    span{
        color: #fff;
        text-transform: uppercase;
    } 
}

.avatar-name-header-holder{

}

.chat-header-gallery{
    display: flex;
    flex-wrap: wrap;
    .chat-header-gallery-item {
        margin-right: 4px;
        border: 1px solid #f5f5f5;
        img{
            height: 42px;
            width: 42px; 
            object-fit: cover;   
        }
    }

}
.messageCardBody{
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}