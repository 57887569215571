
.disabled{
    background: transparent !important;
    border-color: transparent !important;
}

// .loading{
//     position: absolute;
//     top: 0;
//     // margin: auto;
// }
.chat-footer{
    position: relative;
}
.default-words-wrapper{
    position: absolute;
    width: 100%;
    z-index: 10;
    top: -25px;
    // visibility: hidden;
    opacity: 0;
    transition: all 0.15s linear;
    left: 0;

    &.visible{
        visibility: visible;
        opacity: 1;
    }
}
.default-words-box{
    display: flex;
    width: 100%;
    // height: 30px;
    margin: 0 -10px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}
.default-word{
    padding: 5px 10px;

    span{
    color: #000;
    border: 1px solid;
    border-color: #f4f4f4;
    background-color: #f5f7fb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 10px;
    cursor: pointer;
    // box-shadow: 0px 1px 8px #7777;
    white-space:nowrap;
        
    }
}

.onlineButton{
    >button{

        background-color: transparent !important;
        border-color: transparent !important;
        padding: 0;
        color: #000 !important;
        &:hover{
            background-color: transparent !important;
            border-color: transparent !important;
        color: #000 !important;

        }
    }
}

.microphone{
    font-size: 22px;
    cursor: pointer;
}

.inputBox{
    flex: 1;

.input{
    // padding-right: 40px;

}
}
.disabled-button{
    opacity: 0.5;
    cursor: none;
}

.chatUserName{
    text-overflow: ellipsis;
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
}